import Layout from "components/layout";
import LP from "components/LP";
import React from "react";
import SEO from "components/seo";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Fast Translation Services - Delivery Within 24 Hours"
        description="Fast and reliable translation and website localization solutions. We translate legal, medical, and other technical documents. ISO certified. 120+ Languages."
      />
      <LP />
    </Layout>
  );
};

export default index;
