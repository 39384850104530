import FirstAdComp from "components/homepage/Ads-Page/firstAdComp";
import SecondAdComp from "components/homepage/Ads-Page/secondAdComp";
import React from "react";
import OurClients from "./ourClients";
import ThirdComp from "./thirdComp";
import { NewData } from "../homepage/Ads-Page/config";
import AdContact from "components/homepage/Ads-Page/AdContact";
import SeventhAdComp from "components/homepage/websiteTranslation/seventhAdComp";
import About from "./About";
import Industries from "./Industries";
import RelativeLanguages from "./RelativeLanguages";
import Services from "./Services";
import { ServicesData } from "components/LP/data";
import ReviewSection from "./review";
import AdsCarousel from "components/homepage/Ads-Page/carosule";
import { useState } from "react";
import { DocumentData } from "components/LP/data";

const LP = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);

  if (typeof window !== "undefined") {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  return (
    <>
      <FirstAdComp
        heading="Professional"
        heading2="Translation Services"
        content="The Best Translation Experience Guaranteed."
        content2="World-Class Expert Translators."
        FormDesc="Share your details, so we can better understand your needs. We'll respond within minutes."
        FromTextColor="Order"
        FromText1="Translations Online"
        // FromText2="Fast Translation Services"
        fontSize="text-[16px] sm:text-[18px]"
        marginTop="sm:mt-[37px]"
        HeadfontSize="sm:text-[45px]"
        paddingY="sm:pb-10 sm:pt-0"
        bottomService
        contentTwo="Providing clients with the optimal translation experience is the heart of our service. Quick delivery, 24/7 service. Quality and expertise at competitive rates."
        hideContent
      />
      <OurClients />
      <ThirdComp />
      <SecondAdComp
        midContent
        data={NewData}
        parentDivwidth
        customWidth="max-w-[300px]"
        newCarousel
        headTitle="Why Translate With Us?"
        fontBold
        title="Why Translate With Us?"
        gapX
        noPadding
      />
      <AdContact
        open={open}
        setOpen={setOpen}
        head="Ready to get started?"
        paddingY="pt-14 sm:pb-10"
        mobileContactButton
      />
      <SeventhAdComp
        serviceSection
        content="Tomedes is recognized by the International Organization for Standards, the foremost authority on industry standards in the world. The company is ISO-certified in three categories relevant to translation services:"
        head="Exceptional Standards Accredited by the ISO"
      />
      <RelativeLanguages open={open} setOpen={setOpen} />
      <Industries open={open} setOpen={setOpen} DocumentData={DocumentData} />
      <ReviewSection open={open} setOpen={setOpen} />
      <SecondAdComp noContent BottomSection NotshowCarousel />
      <About />
    </>
  );
};

export default LP;
